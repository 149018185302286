import DatePicker from "react-datepicker";

interface Props {
    selectedValue: Date | null;
    onDateChange: (value: Date) => void;
    lastDayOfMonth?: boolean;
}

export default function CustomDatePicker(props: Props) {
    function handleKeyDown(e: React.KeyboardEvent<HTMLElement>): void {
        if (e.key === 'Enter') {
            const input = (e?.target as unknown as { value: string } | null)?.value;
            if (!input) {
                return;
            }
            formatDate(input);
        }
    }

    function handleDateChange (date: Date | null) {
        if (date && date.getFullYear() >= 2000 && date.getFullYear() < 2100)
        {
            if (props.lastDayOfMonth && date) {
                const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                props.onDateChange(lastDay);
            } else {
                props.onDateChange(date);
            }
        }
    }
    
    function handleOnBlur(e: React.FocusEvent<HTMLInputElement>)  {
        const input = (e?.target as unknown as { value: string } | null)?.value;
        if (!input) {
            return;
        }
        formatDate(input);
    }
    
    function formatDate(input: string){
        setTimeout(() => {
            let date: Date | null = null;

            if (/^\d{2}-\d{2}-\d{2}$/.test(input)) {
                // YY-MM-DD
                const [year, month, day] = input.split('-');
                date = new Date(`20${year}-${month}-${day}`);
            } else if (/^\d{4}-\d{2}-\d{2}$/.test(input)) {
                // YYYY-MM-DD
                date = new Date(input);
            } else if (/^\d{6}$/.test(input)) {
                // YYMMDD
                date = new Date(`20${input.slice(0,2)}-${input.slice(2,4)}-${input.slice(4,6)}`);
            } else if (/^\d{8}$/.test(input)) {
                // YYYYMMDD
                date = new Date(`${input.slice(0,4)}-${input.slice(4,6)}-${input.slice(6,8)}`);
            }

            if (date && !isNaN(date.getTime()) && date.getFullYear() >= 2000 && date.getFullYear() < 2100) {
                handleDateChange(date);
            }
        }, 10)
    }
    
    return (
        <DatePicker
            showIcon
            calendarIconClassName={"react-datepicker__custom-calendar-icon"}
            toggleCalendarOnIconClick
            selected={props.selectedValue ? new Date(props.selectedValue) : null}
            onChange={(date) => handleDateChange(date)}
            dateFormat="yyyy-MM-dd"
            filterDate={props.lastDayOfMonth ? (date: Date) => date.getDate() === new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate() : undefined}
            className="form-control"
            onKeyDown={handleKeyDown}
            onBlur={handleOnBlur}
        >
        </DatePicker>
    )
}